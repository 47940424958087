import $ from 'jquery';
import isScreen from '@/core/screenHelper';
import moment from 'moment';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    sidebarClose: false,
    sidebarStatic: false,
    sidebarActiveElement: null,
    content: null,
    chatOpen: false,
    nowYear: moment().year(),
    tabIdActivated: null,
    exportInProgress: false
  },
  mutations: {
    toggleChat(state) {
      state.chatOpen = !state.chatOpen;
      $('.chat-notification-sing').remove();

      setTimeout(() => {
        // demo: add class & badge to indicate incoming messages from contact
        // .js-notification-added ensures notification added only once
        $('#chat-sidebar-user-group').find('.list-group-item:first-child:not(.js-notification-added)')
          .addClass('active js-notification-added')
          .find('.fa-circle')
          .after('<span class="badge badge-danger badge-pill '
            + 'float-right animated bounceInDown">3</span>');
      }, 1000);
    },
    toggleSidebar(state) {
      const nextState = !state.sidebarStatic;

      localStorage.sidebarStatic = nextState;
      state.sidebarStatic = nextState;

      if (!nextState && (isScreen('lg') || isScreen('xl'))) {
        state.sidebarClose = true;
      }
    },
    switchSidebar(state, value) {
      if (value) {
        state.sidebarClose = value;
      } else {
        state.sidebarClose = !state.sidebarClose;
      }
      if (isScreen('md') || isScreen('xs') || isScreen('sm')) {
        state.sidebarStatic = false;
      }
    },
    setSidebarState(state, value) {
      state.sedebarClose = value;
    },
    handleSwipe(state, e) {
      if (e.direction === 4) {
        state.sidebarClose = false;
      }

      if (e.direction === 2 && !state.sidebarClose) {
        state.sidebarClose = true;
        return;
      }
    },
    changeSidebarActive(state, index) {
      state.sidebarActiveElement = index;
    },
    setExportInProgress(state, value) {
      Vue.set(state, 'exportInProgress', value)
    },
    setTabIdActivated(state, value) {
      Vue.set(state, 'tabIdActivated', value)
    },
    changeContent(state, content_ref) {
      Vue.set(state, 'content', content_ref)
    }
  },
  actions: {
    toggleChat({commit}) {
      commit('toggleChat');
    },
    toggleSidebar({commit}) {
      commit('toggleSidebar');
    },
    switchSidebar({commit}, value) {
      commit('switchSidebar', value);
    },
    handleSwipe({commit}, e) {
    },
    changeSidebarActive({commit}, index) {
      commit('changeSidebarActive', index);
    },
    checkAndUpdateContent({commit, state}, content_ref) {
      if (state.content !== content_ref) {
        commit('changeContent', content_ref);
      }
    }
  },
};
