export const CURRENCY_CODE = "EUR";

export const CATALOG_ITEM_TYPES = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  RANGE: 'range'
};

export const CATALOG_ITEM_COMPONENTS = {
  [CATALOG_ITEM_TYPES.CHECKBOX]: 'CatalogFilterItem',
  [CATALOG_ITEM_TYPES.RADIO]: 'CatalogFilterItem',
  [CATALOG_ITEM_TYPES.RANGE]: 'CatalogFilterItemRange'
};

export const LOCALE_LANGUAGES = {
  'sk': 'sk-SK',
  'cs': 'cs-CZ',
  'en': 'en-US',
  'ro': 'ro-RO',
  'sl': 'sl-SI',
  'hu': 'hu-HU',
  'hr': 'hr-HR',
};

export const COLUMN_FILTER_TYPES = {
  DATE: 'date',
  RANGE: 'range',
  TEXT: 'text',
  BOOL: 'bool',
  LIST: 'list',
  EXACT: 'exact',
};

export const FILTER_COMPONENTS = {
  [COLUMN_FILTER_TYPES.DATE]: 'date-filter',
  [COLUMN_FILTER_TYPES.RANGE]: 'range-filter',
  [COLUMN_FILTER_TYPES.BOOL]: 'bool-filter',
  [COLUMN_FILTER_TYPES.LIST]: 'list-filter'
}

export const BASKET_ITEM_TYPES = {
  ITEM_MULTISELECT: 'item_multiselect',
  BASE_PRICE: 'base_price',
  CUSTOMER_PRICE: 'customer_price',
  EDITABLE_PRICE: 'editable_price',
  READ_ONLY_CELL: 'read_only_cell',
  QUANTITY_PICKER: 'quantity_picker',
  REMOVE_BUTTON: 'remove_button',
  ITEM_INPUT: 'item_input',
  RAW_HTML: 'raw_html',
}

export const BASKET_ITEM_COMPONENTS = {
  [BASKET_ITEM_TYPES.ITEM_MULTISELECT]: 'basket-item-multiselect',
  [BASKET_ITEM_TYPES.BASE_PRICE]: 'basket-item-base-price',
  [BASKET_ITEM_TYPES.CUSTOMER_PRICE]: 'basket-item-price',
  [BASKET_ITEM_TYPES.EDITABLE_PRICE]: 'basket-item-price-input',
  [BASKET_ITEM_TYPES.READ_ONLY_CELL]: 'basket-item-read-only-cell',
  [BASKET_ITEM_TYPES.QUANTITY_PICKER]: 'basket-item-quantity-picker',
  [BASKET_ITEM_TYPES.REMOVE_BUTTON]: 'basket-item-remove-button',
  [BASKET_ITEM_TYPES.ITEM_INPUT]: 'basket-item-input',
  [BASKET_ITEM_TYPES.RAW_HTML]: 'basket-item-raw-html',
}

export const ASYNC_LINE_STATES = {
  WAITING: 'waiting', // used when line was still not send to BE
  ERROR: 'err', // used when return error
  TIMEOUT: 'timeout', // used when return timeout error
  PROCESSING: 'processing', // used when request was send to BE but not resolved yet
};

export const ASYNC_LINE_TYPES = {
  ADD_LINE: 'addLine',
  CHANGE_QTY: 'changeQty',
  REMOVE_LINE: 'removeLine',
  REMOVE_PRODUCT: 'removeProduct'
};

export const ASYNC_UPDATE_TYPES = {
  DELETE: 'delete',
  CHANGE_QTY: 'change_qty',
  PROCESS: 'process',
  ERROR: 'err',
  TIMEOUT: 'timeout',
};
